<template scoped>
  <div :style="{ height: '100%', position: 'relative' }" v-loading="loading">
    <el-page-header
      :style="{ margin: '10px' }"
      @back="back"
      content="Design Tool"
    >
    </el-page-header>

    <div
      v-infinite-scroll="loadMore"
      :style="{
        padding: '10px',
        height: 'calc(100% - 64px)',
        width: '100%',
        overflowY: 'auto',
      }"
    >
      <DesignToolLogic-Start> </DesignToolLogic-Start>
      <component
        v-for="item in getLogic"
        :key="item.id"
        :is="item.compoment"
        :id="item.id"
        :description="item.description"
        :variables="item.variables"
        :deleteLogic="deleteLogic"
        :editLogic="editLogic"
      ></component>
      <DesignToolLogic-Add-Logic :addLogic="addLogic">
      </DesignToolLogic-Add-Logic>
      <DesignToolLogic-End> </DesignToolLogic-End>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    toPage: Function,
    parameter: Object,
    endpoint: String,
  },
  data: function () {
    return {
      loading: true,
      logic: [],
      page: 1,
      pageSize: 10,
      noMore: true,
    };
  },
  computed: {
    getLogic: function () {
      return this.logic.map(function (x) {
        var result = {
          id: x.id,
          description: x.description,
          variables: x.variables,
        };

        switch (x.action) {
          case "Connect":
            result.compoment = () => import("./DesignTool/Connect");
            break;
          case "Send":
            result.compoment = () => import("./DesignTool/Send.vue");
            break;
          case "Execute":
            result.compoment = () => import("./DesignTool/Execute.vue");
            break;
          case "Operator":
            result.compoment = () => import("./DesignTool/Operator.vue");
            break;
          case "Read":
            result.compoment = () => import("./DesignTool/Read.vue");
            break;
          case "Save":
            result.compoment = () => import("./DesignTool/Save.vue");
            break;
        }

        return result;
      });
    },
  },
  created: function () {
    this.refresh();
  },
  components: {
    "DesignToolLogic-Start": () => import("./DesignTool/Start.vue"),
    "DesignToolLogic-Add-Logic": () => import("./DesignTool/Add-Logic.vue"),
    "DesignToolLogic-End": () => import("./DesignTool/End.vue"),
  },
  methods: {
    back: function () {
      this.toPage("components/Flow/Portal-Flow.vue", {
        filter: this.getFilter(),
      });
    },
    getFilter: function () {
      if (this.parameter) {
        if (this.parameter.filter) {
          return this.parameter.filter;
        }
      }
      return [];
    },
    refresh: function () {
      var $this = this;
      this.loading = true;
      this.page = 1;
      this.pageSize = 10;
      this.noMore = true;
      this.$plugin.request
        .get(
          `${this.endpoint}/api/Backend/FlowLogic?FlowId=${this.parameter.flowId}&Page=${this.page}&PageSize=${this.pageSize}`
        )
        .then(function (response) {
          $this.logic = response.result;
          $this.loading = false;
          if ($this.page >= response.totalPages) {
            $this.noMore = true;
          } else {
            $this.noMore = false;
          }
        });
    },
    loadMore: function () {
      if (!this.noMore) {
        var $this = this;
        this.page += 1;
        this.$plugin.request
          .get(
            `${this.endpoint}/api/Backend/FlowLogic?FlowId=${this.parameter.flowId}&Page=${this.page}&PageSize=${this.pageSize}`
          )
          .then(function (response) {
            $this.logic = $this.logic.concat(response.result);
            if ($this.page >= response.totalPages) {
              $this.noMore = true;
            }
          });
      }
    },
    addLogic: function () {
      this.toPage("components/FlowLogic/Portal-FlowLogic-CreateUpdate.vue", {
        flowId: this.parameter.flowId,
        productId: this.parameter.productId,
      });
    },
    editLogic: function (id) {
      this.toPage("components/FlowLogic/Portal-FlowLogic-CreateUpdate.vue", {
        flowId: this.parameter.flowId,
        productId: this.parameter.productId,
        id: id,
      });
    },
    deleteLogic: function (id) {
      var $this = this;

      this.$plugin.request
        .delete(`${this.endpoint}/api/Backend/FlowLogic/${id}`)
        .then(function () {
          $this.refresh();
        });
    },
  },
};
</script>

<style scoped>
.dialog {
  position: absolute;
  background: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  top: -10px;
  left: 0px;
}

.dialog > div {
  border-radius: 10px;
  background: #fff;
  height: calc(100% - 80px);
  margin: 40px;
}
</style>